import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ConfigContext } from './ConfigProvider';
import { convertFromCsv } from './CsvConverter';
import { Loading } from '../components/Loading';

export type Row = Record<string, string | number>;
export type DataSource = Row[];

export const DataSourceContext = createContext<DataSource>([]);

export type DataSourceProviderProps = {};
export const DataSourceProvider = (
  props: PropsWithChildren<DataSourceProviderProps>
) => {
  const config = useContext(ConfigContext);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  const sleep = (data: Response, ms: number): Promise<Response> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(data);
      }, ms);
    });
  };

  useEffect(() => {
    if (config.datasourceUrl) {
      fetch(config.datasourceUrl)
        .then(async (response) => {
          return await sleep(response, 1);
        })
        .then(async (response) => {
          if (config.datasourceType === 'json') {
            return await response.json();
          }
          if (config.datasourceType === 'csv') {
            const data = await response.text();
            return await convertFromCsv(data, config);
          }

          throw new Error(
            `[DataSourceProvider] Invalid datasource type '${config.datasourceType}'`
          );
        })
        .catch((reason) => {
          console.error(
            `[DataSourceProvider] Error fetching '${config.datasourceType}' from URL ${config.datasourceUrl}:`,
            reason
          );
          setError(true);
        })
        .then((data) => setData(data));
    }
  }, []);

  return (
    <DataSourceContext.Provider value={data}>
      {error && (
        <div
          className="error"
          dangerouslySetInnerHTML={{
            __html:
              config.loadErrorText ||
              '<h3>Something went wrong</h3><p>There was a problem loading your data.<br/>Please try again later.</p>',
          }}
        ></div>
      )}
      {!error && data.length === 0 && <Loading />}
      {!error && data.length > 0 && props.children}
    </DataSourceContext.Provider>
  );
};
