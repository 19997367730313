import React, { useEffect, useState } from 'react';

import './Loading.scss';

export const Loading = () => {
  const [loadingPercent, setLoadingPercent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingPercent(loadingPercent === 100 ? 0 : loadingPercent + 1);
    }, 100);
    return () => clearInterval(interval);
  }, [loadingPercent]);

  return (
    <div className="progress-indicator">
      <span
        className="progress-indicator-bar"
        style={{ width: loadingPercent + '%' }}
      >
        Loading...
      </span>
    </div>
  );
};
