import React, { useContext, useState } from 'react';
import { Results, ResultsContext } from '../../providers/ResultsStateProvider';

import './ResultsTable.scss';
import {
  ConfigContext,
  DisplayField,
  SortField,
} from '../../providers/ConfigProvider';
import { Row } from '../../providers/DataSourceProvider';

export type GroupedResultRow = Record<
  string,
  boolean | string | number | Row[]
>;
export type GroupedResultRows = GroupedResultRow[];

export type ResultsTableProps = {
  formatters?: Record<
    string,
    ({ row }: { row: GroupedResultRow }) => React.JSX.Element
  >;
  headingFormatters?: Record<string, (arg0: string) => React.JSX.Element>;
  results?: GroupedResultRows;
  showNumberResults?: boolean;
};
export const ResultsTable = (props: ResultsTableProps) => {
  const config = useContext(ConfigContext);
  let data: Results | GroupedResultRows =
    props.results || useContext(ResultsContext);

  const [defaultSortType, defaultSortDirection] =
    config.defaultSortMethod === undefined
      ? ['alpha', 'asc']
      : config.defaultSortMethod.split(':');

  const [sortField, setSortField] = useState(config.defaultSortField);
  const [sortType, setSortType] = useState(defaultSortType || 'alpha');
  const [sortDirection, setSortDirection] = useState(
    defaultSortDirection || 'asc'
  );

  const onSortClick = (field?: SortField) => {
    return () => {
      if (field) {
        if (
          (!field.sortField && field.field !== sortField) ||
          (field.sortField && field.sortField !== sortField)
        ) {
          setSortField(field.sortField || field.field);
        } else {
          setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        }
      }
    };
  };

  data.sort((a: GroupedResultRow, b: GroupedResultRow) => {
    if (sortField === undefined) {
      return 0;
    }

    const lessThan = sortDirection === 'asc' ? -1 : 1;
    const greaterThan = sortDirection === 'asc' ? 1 : -1;

    if (a[sortField] < b[sortField]) {
      return lessThan;
    }
    if (a[sortField] > b[sortField]) {
      return greaterThan;
    }

    return 0;
  });

  const groupRowsBy = (data: GroupedResultRows, groupByField: string) => {
    const groupedData: GroupedResultRows = [];

    data.forEach((row) => {
      let groupedRow = groupedData.findIndex(
        (x) => x[groupByField] === row[groupByField]
      );
      if (groupedRow === -1) {
        groupedRow = groupedData.push({ ...row }) - 1;
        groupedData[groupedRow]['__grouped'] = true;
      }

      config.displayFields?.forEach((field) => {
        if (groupedRow !== -1 && field.group) {
          if (!(groupedData[groupedRow][field.field] as Row[]).push) {
            groupedData[groupedRow][field.field] = [{ ...(row as Row) }];
          } else {
            (groupedData[groupedRow][field.field] as Row[]).push({
              ...(row as Row),
            });
          }
        }
      });
    });

    return groupedData;
  };

  if (config.groupRowsByField) {
    data = groupRowsBy(data, config.groupRowsByField);
  }

  const renderField = (row: GroupedResultRow, field: DisplayField) => {
    if (row['__grouped'] && (row[field.field] as Row[]).map) {
      let FieldFormatter = props.formatters && props.formatters[field.field];
      return FieldFormatter ? (
        <FieldFormatter row={row as Row} />
      ) : (
        <span>[field formatter not defined]</span>
      );
    } else {
      return row[field.field] as string;
    }
  };

  const renderHeading = (label: string) => {
    if (props.headingFormatters && props.headingFormatters[label]) {
      return props.headingFormatters[label](label);
    }

    return label;
  };

  return (
    <div className="results">
      {data && data.length === 0 && (
        <p
          className="no-results"
          dangerouslySetInnerHTML={{
            __html: config.noResultsText || 'No results',
          }}
        ></p>
      )}
      {data && data.length > 0 && props.showNumberResults && (
        <p>
          Showing {data.length} result{data.length !== 1 && 's'}
        </p>
      )}
      {data && data.length > 0 && (
        <div className="results-table sunken-panel">
          <table>
            <colgroup>
              {config.displayFields &&
                config.displayFields.map((field) => {
                  return (
                    <col key={field.label} style={{ width: field.width }} />
                  );
                })}
            </colgroup>
            <thead>
              <tr>
                {config.displayFields &&
                  config.displayFields.map((field) => {
                    const sortOptions =
                      config.sortableFields &&
                      config.sortableFields.find(
                        (x) => x.field === field.field
                      );

                    const classes: string[] = [];
                    if (sortOptions) {
                      classes.push('sortable');
                    }

                    if (
                      sortField &&
                      (sortField === field.field ||
                        sortField === sortOptions?.sortField)
                    ) {
                      classes.push(`sorted-${sortDirection}`);
                    }

                    return (
                      <th
                        key={field.label}
                        className={classes.join(' ')}
                        onClick={onSortClick(sortOptions)}
                      >
                        {renderHeading(field.label)}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((row) => (
                  <tr key={row[config.idField || 'id']}>
                    {config.displayFields &&
                      config.displayFields.map((field) => {
                        return (
                          <td
                            key={
                              row[config.idField || 'id'].toString() +
                              field.field
                            }
                          >
                            {renderField(row, field)}
                          </td>
                        );
                      })}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
