import React, { PropsWithChildren, useContext } from 'react';
import { ConfigContext, Filter } from '../../providers/ConfigProvider';
import { DataSourceContext, Row } from '../../providers/DataSourceProvider';
import { SelectFilter } from '../fields/SelectFilter';
import {
  FilterContext,
  FilterState,
} from '../../providers/FilterStateProvider';

import './Filters.scss';
import { ResultsContext } from '../../providers/ResultsStateProvider';

export type FiltersProps = {};
export const Filters = (props: PropsWithChildren<FiltersProps>) => {
  const config = useContext(ConfigContext);

  if (!config.filters) {
    return null;
  }

  const data = useContext(DataSourceContext);
  const filterState = useContext(FilterContext);
  const results = useContext(ResultsContext);
  const requiredFilters = config.filters?.filter((x) => x.required);

  const initialData = data.filter((row) => {
    if (
      requiredFilters &&
      requiredFilters.every(
        (x) => filterState.filters !== undefined && filterState.filters[x.field]
      )
    ) {
      return requiredFilters.every(
        (x) =>
          filterState.filters && row[x.field] === filterState.filters[x.field]
      );
    }
    return true;
  });

  const flattenFilters = (filters: Filter[]) => {
    return filters.flatMap((filter) => {
      if (filter.type === 'group' && filter.fields) {
        return flattenFilters(filter.fields);
      }
      return filter;
    });
  };

  const flatFilters = flattenFilters(config.filters || []);

  const buildFilters = (filters: Filter[]) => {
    return filters.map((filter) => {
      if (
        filter.dependsOn &&
        !(filterState.filters && filterState.filters[filter.dependsOn])
      ) {
        return null;
      }

      if (filter.type === 'group') {
        return (
          <div className="filter-group" key={filter.id}>
            <h3>{filter.label}</h3>
            <div className="grouped-filters">
              {filter.fields && buildFilters(filter.fields)}
            </div>
          </div>
        );
      }

      const checkFilter = (
        match: boolean,
        row: Record<string, string | number>,
        filter: Filter,
        filterState: FilterState
      ) => {
        if (!filterState.filters) {
          return false;
        }

        if (filter.required && !filterState.filters[filter.id]) {
          return false;
        } else if (
          filterState.filters[filter.id] !== '*' &&
          row[filter.field] !== filterState.filters[filter.id]
        ) {
          return false;
        } else if (filter.type === 'group' && filter.fields) {
          match = filter.fields?.every((x) =>
            checkFilter(match, row, x, filterState)
          );
        }

        return match;
      };

      const checkCrossfilter = (row: Row) => {
        if (!filterState.filters) {
          return false;
        }

        const filteredData = initialData.filter((row) => {
          let match = true;
          if (flatFilters) {
            flatFilters.forEach((f) => {
              if (match && filterState.filters && f.field !== filter.field) {
                match = checkFilter(match, row, f, filterState);
              }
            });
          }

          return match;
        });

        const optionValue = row[filter.field];
        const match =
          filteredData.findIndex((row) => optionValue === row[filter.field]) >
          -1;

        return match;
      };

      // console.time(`filtering filters - ${initialData.length}`);
      const options = [
        filter.allowAll
          ? { label: config.selectAllText || 'All', value: '*' }
          : { label: config.pleaseSelectText || 'Please select...', value: '' },
        ...data
          .filter(
            (row) =>
              !filter.crossFilter ||
              (filter.crossFilter && checkCrossfilter(row))
          )
          .map((x) => x[filter.field])
          .filter((item, i, arr) => arr.indexOf(item) === i)
          .sort()
          .map((x) => ({ label: x.toString(), value: x.toString() })),
      ];
      // console.timeEnd(`filtering filters - ${initialData.length}`);

      // Only a single selectable option, make it the default
      if (options.length === 2) {
        options.splice(0, 1);
      }

      const defaultValue =
        filterState.filters && filterState.filters[filter.id]
          ? filterState.filters[filter.id]
          : filter.default
          ? filter.default
          : filter.allowAll
          ? '*'
          : '';

      return (
        <div className="filter" key={filter.id}>
          {filter.introText && <h3>{filter.introText}</h3>}
          <label htmlFor={filter.id}>{filter.label}</label>
          {filter.type === 'select' && (
            <SelectFilter
              key={filter.id}
              id={filter.id}
              options={options}
              defaultValue={defaultValue}
            />
          )}
          {!['select'].includes(filter.type) && (
            <b style={{ color: 'darkred' }}>Invalid filter type</b>
          )}
        </div>
      );
    });
  };

  const resetFilters = () => {
    filterState.resetFilters();
  };

  const filters = buildFilters(config.filters || []);

  return (
    <fieldset className="filters">
      {config.filterIntroText !== undefined && (
        <legend
          dangerouslySetInnerHTML={{
            __html: config.filterIntroText,
          }}
        ></legend>
      )}
      {filters && filters.map((FilterComponent) => FilterComponent)}
      {props.children}
      {config.includeFilterReset && (
        <div className="filter-actions">
          <button
            type="reset"
            className="reset-filters-btn"
            onClick={resetFilters}
            disabled={results.length === 0}
          >
            {config.filterResetText}
          </button>
        </div>
      )}
    </fieldset>
  );
};
