import { Config } from './ConfigProvider';
import { DataSource, Row } from './DataSourceProvider';
import { parse } from 'papaparse';

export const convertFromCsv = async (
  data: string,
  config: Config
): Promise<DataSource> => {
  const parsed = parse(data, {
    header: true,
  });

  if (config) {
    (parsed.data as DataSource).forEach((x, i) => {
      const idField = config.idField ? config.idField : '_id';
      if (idField && !x[idField]) {
        x[idField] = i.toString();
      }
    });
  }

  return parsed.data as DataSource;
};
