import React, { ChangeEvent, PropsWithChildren, useContext } from 'react';
import { FilterContext } from '../../providers/FilterStateProvider';

export type SelectFilterProps = {
  id: string;
  defaultValue: string;
  options: Array<{ label: string; value: string }>;
};
export const SelectFilter = (props: PropsWithChildren<SelectFilterProps>) => {
  const filterContext = useContext(FilterContext);

  const options = props.options
    ?.filter((x) => x.label !== '')
    .map((option) => {
      return (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      );
    });

  const onFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (filterContext.filters) {
      filterContext.updateFilterValue(
        props.id,
        e.target.options[e.target.selectedIndex].value,
        filterContext.filters
      );
    }
  };

  return (
    <>
      <select onChange={onFilterChange} value={props.defaultValue}>
        {options && options.map((x) => x)}
      </select>
      {/* {props.id === 'EnglishQual_Name' && ( */}
      {/* <pre>
        {JSON.stringify(
          props.options.map((x) => x.value),
          null,
          4
        )}
      </pre> */}
      {/* )} */}
    </>
  );
};
