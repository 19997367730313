import React, { StrictMode } from 'react';

import { createRoot } from 'react-dom/client';
import App from './App';

const $apps = document.querySelectorAll<HTMLElement>(
  '[data-cmp-is="data-search"]'
);
$apps.forEach(($app) => {
  const root = createRoot($app);
  const config = $app.dataset.config ? JSON.parse($app.dataset.config) : {};
  root.render(
    <StrictMode>
      <App config={config} />
    </StrictMode>
  );
});
