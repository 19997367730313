import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';
import { ConfigContext, Filter } from './ConfigProvider';

export type Filters = Record<string, string>;

export type FilterState = {
  filters?: Filters;
  updateFilterValue: (id: string, value: string, filters: Filters) => void;
  resetFilters: () => void;
};

export const FilterContext = createContext<FilterState>({
  filters: {},
  updateFilterValue: (id: string, value: string, filters: Filters) => {},
  resetFilters: () => {},
});

export type FilterStateProviderProps = {};
export const FilterStateProvider = (
  props: PropsWithChildren<FilterStateProviderProps>
) => {
  const config = useContext(ConfigContext);

  const [dbg, setDbg] = useState<string[]>([]);

  const flattenFilters = (filters: Filter[]) => {
    return filters.flatMap((filter) => {
      if (filter.type === 'group' && filter.fields) {
        return flattenFilters(filter.fields);
      }
      return filter;
    });
  };

  const flatFilters: Filter[] = flattenFilters(config.filters || []);

  let defaultFilters: Filters = {};
  const buildDefaultFilters = (filters: Filter[]) => {
    return filters
      .filter((x) => x.default || x.type === 'group')
      .forEach((filter) => {
        if (filter.type === 'group' && filter.fields) {
          buildDefaultFilters(filter.fields);
        } else {
          defaultFilters[filter.id] = filter.default;
        }
      });
  };

  const getHashParams = () => {
    return window.location.hash === ''
      ? undefined
      : Object.fromEntries(
          window.location.hash
            .substring(1)
            .split('&')
            .map((x) => x.split('='))
        );
  };
  const hashParams = getHashParams();

  const updateHashParam = (
    params: Record<string, string>,
    key: string,
    value: string
  ) => {
    params[key] = value;
    window.location.hash = Object.keys(params)
      .map((k) => `${k}=${params[k]}`)
      .join('&');
  };

  const buildFiltersFromUrl = (filters: Filter[]) => {
    return filters.forEach((filter) => {
      if (filter.type === 'group' && filter.fields) {
        buildFiltersFromUrl(filter.fields);
      } else {
        if (hashParams[filter.id] !== undefined) {
          defaultFilters[filter.id] = decodeURIComponent(hashParams[filter.id]);
        }
      }
    });
  };

  buildDefaultFilters(config.filters || []);

  // TODO: Probably need to do all this on hashchange so that back/forward works
  if (config.persistFiltersInUrl && hashParams) {
    buildFiltersFromUrl(config.filters || []);
  }

  const updateFilterValue = (id: string, value: string, filters: Filters) => {
    setDbg((oldArray) => [...oldArray, `updateFilter: ${id} = ${value}`]);
    if (flatFilters.find((x) => x.id === id && x.resetDependents)) {
      flatFilters
        .filter((x) => x.dependsOn === id)
        .forEach((x) => {
          filters[x.id] = x.allowAll ? '*' : '';
        });
    }

    if (config.persistFiltersInUrl) {
      const hashParams = getHashParams() || {};
      updateHashParam(hashParams, id, value);
    }

    filters[id] = value;
    if (value === '') {
      delete filters[id];
    }

    setFilter({
      ...filter,
      filters: filters,
    });
  };

  const resetFilters = () => {
    defaultFilters = {};
    buildDefaultFilters(config.filters || []);
    setFilter({
      filters: defaultFilters,
      updateFilterValue,
      resetFilters,
    });
    window.location.hash = '';
  };

  const [filter, setFilter] = useState<FilterState>({
    filters: defaultFilters,
    updateFilterValue,
    resetFilters,
  });

  const toggleDebugWindow = () => {
    document.body.classList.toggle('show-debug');
  };

  return (
    <FilterContext.Provider value={filter}>
      {props.children}

      <div className="window debug">
        <div className="title-bar">
          <div className="title-bar-text">[dbg]</div>
          <div className="title-bar-controls">
            <button aria-label="Close" onClick={toggleDebugWindow}></button>
          </div>
        </div>
        <div className="window-body">
          <pre>
            {JSON.stringify(filter.filters, null, 2)}
            {'\n\n---\n\n'}
            {dbg.join('\n')}
          </pre>
        </div>
        <div className="status-bar">
          <p className="status-bar-field">Splines Reticulated: 100%</p>
          <p className="status-bar-field">CPU Usage: 14%</p>
        </div>
      </div>
    </FilterContext.Provider>
  );
};
