import React, { PropsWithChildren, createContext, useState } from 'react';

export type Filter = {
  id: string;
  field: string;
  introText?: string;
  label: string;
  type: 'group' | 'select';
  sort: 'alpha';
  allowAll: boolean;
  required: boolean;
  default: string;
  dependsOn: string;
  resetDependents?: boolean;
  fields?: Filter[];
  crossFilter: boolean;
};

export type DisplayField = {
  field: string;
  label: string;
  group?: boolean;
  width?: string;
};

export type ResultsGrouping = {
  field: string;
  showNumberTotalResults: boolean;
  showNumberGroupResults: boolean;
  groups: {
    contains: string;
    label: string;
  }[];
};

export type SortMethods = 'alpha:asc' | 'alpha:desc';

export type SortField = {
  field: string;
  sortField?: string;
};

export type Config = {
  title?: string;
  filterIntroText?: string;
  noResultsText?: string;
  datasourceUrl?: string;
  datasourceType?: 'json' | 'csv';
  filters?: Filter[];
  idField?: string;
  defaultSortField?: string;
  defaultSortMethod?: SortMethods;
  sortableFields?: SortField[];
  groupRowsByField?: string;
  displayFields?: DisplayField[];
  persistFiltersInUrl?: boolean;
  includeFilterReset?: boolean;
  filterResetText?: string;
  pleaseSelectText?: string;
  selectAllText?: string;
  loadErrorText?: string;
  resultsGrouping?: ResultsGrouping;
};

export const ConfigContext = createContext<Config>({});

export type ConfigProviderProps = {
  config: any;
};
export const ConfigProvider = (
  props: PropsWithChildren<ConfigProviderProps>
) => {
  const [config] = useState(props.config);
  return (
    <ConfigContext.Provider value={config}>
      {props.children}
    </ConfigContext.Provider>
  );
};
