import React, { PropsWithChildren, createContext, useContext } from 'react';
import { DataSourceContext } from './DataSourceProvider';
import { FilterContext, FilterState } from './FilterStateProvider';
import { ConfigContext, Filter } from './ConfigProvider';

export type Results = Record<string, string | number>[];

export const ResultsContext = createContext<Results>([]);

export type ResultsStateProviderProps = {};
export const ResultsStateProvider = (
  props: PropsWithChildren<ResultsStateProviderProps>
) => {
  const config = useContext(ConfigContext);
  const data = useContext(DataSourceContext);
  const filter = useContext(FilterContext);

  const checkFilter = (
    match: boolean,
    row: Record<string, string | number>,
    filter: Filter,
    filterState: FilterState
  ) => {
    if (!filterState.filters) {
      return false;
    }

    if (filter.type === 'group' && filter.fields) {
      match = filter.fields?.every((x) =>
        checkFilter(match, row, x, filterState)
      );
    } else if (filter.required && !filterState.filters[filter.id]) {
      match = false;
    } else if (
      filterState.filters[filter.id] !== '*' &&
      row[filter.field] !== filterState.filters[filter.id]
    ) {
      match = false;
    }

    return match;
  };

  const filteredData = data.filter((row) => {
    let match = true;

    if (config.filters) {
      config.filters.forEach((f) => {
        if (match && filter.filters) {
          match = checkFilter(match, row, f, filter);
        }
      });
    }

    return match;
  });

  return (
    <>
      <ResultsContext.Provider value={filteredData}>
        {props.children}
      </ResultsContext.Provider>
    </>
  );
};
