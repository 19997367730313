import React from 'react';
import { Config, ConfigProvider } from './FilterGrid/providers/ConfigProvider';
import { FilterStateProvider } from './FilterGrid/providers/FilterStateProvider';
import {
  Results,
  ResultsStateProvider,
} from './FilterGrid/providers/ResultsStateProvider';

import {
  GroupedResultRow,
  GroupedResultRows,
} from './FilterGrid/components/results/ResultsTable';
import {
  DataSourceProvider,
  Row,
} from './FilterGrid/providers/DataSourceProvider';
import { Filters } from './FilterGrid/components/filters/Filters';
import { GroupedResultsTable } from './FilterGrid/components/results/GroupedResultsTable';

export default function App({ config }: { config: Config }) {
  const groupResults = (
    results: Results | GroupedResultRows
  ): Record<string, Results | GroupedResultRows> => {
    const groups = {};

    const getLabel = (fieldValue: string) => {
      let label;

      if (config.resultsGrouping) {
        config.resultsGrouping.groups.forEach((group) => {
          if (fieldValue.includes(group.contains)) {
            label = group.label;
          }
        });
      }

      return label;
    };

    if (
      config.resultsGrouping &&
      config.resultsGrouping.field &&
      config.resultsGrouping.field !== ''
    ) {
      results.forEach((row) => {
        if (config.resultsGrouping) {
          const type = getLabel(row[config.resultsGrouping.field] as string);
          if (type) {
            if (!groups[type]) {
              groups[type] = [];
            }
            groups[type].push(row);
          }
        }
      });
    }

    return groups;
  };

  const formatters = {
    EnglishQual_Name: ({ row }: { row: GroupedResultRow }) => {
      return (
        <div className="my-row" key={row['Id'] as string}>
          {row['__grouped'] &&
            (row['EnglishQual_Name'] as Row[])
              // Remove duplicates
              .filter(
                (item, pos, self) =>
                  self.findIndex(
                    (v) =>
                      v['EnglishQual_Name'] === item['EnglishQual_Name'] &&
                      v['EnglishEntryRequirement_Narr'] ===
                        item['EnglishEntryRequirement_Narr']
                  ) === pos
              )
              .map((subRow) => {
                return (
                  <div className="my-sub-row" key={subRow['Id']}>
                    <b>{subRow['EnglishQual_Name']}: </b>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: subRow['EnglishEntryRequirement_Narr'],
                      }}
                    ></span>
                  </div>
                );
              })}
        </div>
      );
    },
    AcademicQual_Name: ({ row }: { row: GroupedResultRow }) => {
      return (
        <div className="my-row" key={row['Id'] as string}>
          {row['__grouped'] &&
            (row['AcademicQual_Name'] as Row[])
              // Remove duplicates
              .filter(
                (item, pos, self) =>
                  self.findIndex(
                    (v) =>
                      v['AcademicQual_Name'] === item['AcademicQual_Name'] &&
                      v['AcademicEntryRequirement_Narr'] ===
                        item['AcademicEntryRequirement_Narr']
                  ) === pos
              )
              .map((subRow) => {
                return (
                  <div className="my-sub-row" key={subRow['Id']}>
                    <b>{subRow['AcademicQual_Name']}: </b>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: subRow['AcademicEntryRequirement_Narr'],
                      }}
                    ></span>
                  </div>
                );
              })}
        </div>
      );
    },
  };

  const headingFormattersForTab = (tabName: string) => {
    return {
      Diploma: () => <>{tabName}</>,
    };
  };

  const toggleDebugWindow = () => {
    document.body.classList.toggle('show-debug');
  };
  return (
    <ConfigProvider config={config}>
      <div className="window">
        <div className="title-bar">
          <div className="title-bar-text">{config.title || 'Untitled'}</div>
          <div className="title-bar-controls">
            <button aria-label="Help" onClick={toggleDebugWindow}></button>
            <button aria-label="Minimize"></button>
            <button aria-label="Maximize"></button>
            <button aria-label="Close"></button>
          </div>
        </div>
        <div className="window-body">
          <DataSourceProvider>
            <FilterStateProvider>
              <ResultsStateProvider>
                <Filters>
                  {/* Custom filters could be passed in: */}
                  {/* <Radio id="faculty" value="*" label="Everythin'" />
                  <Radio
                    id="faculty"
                    value="Faculty of Engineering"
                    label="Just Engineering"
                  />
                  <Radio id="faculty" value="Faculty of Arts" label="Just Arts" /> */}
                </Filters>
                {/* <ResultsTable
                  formatters={formatters}
                ></ResultsTable> */}
                <GroupedResultsTable
                  groupResults={groupResults}
                  formatters={formatters}
                  headingFormattersForTab={headingFormattersForTab}
                ></GroupedResultsTable>
              </ResultsStateProvider>
            </FilterStateProvider>
          </DataSourceProvider>
        </div>
      </div>
    </ConfigProvider>
  );
}
