import React, { useContext, useState, MouseEvent, useEffect } from 'react';
import {
  GroupedResultRows,
  ResultsTable,
  ResultsTableProps,
} from './ResultsTable';
import { Results, ResultsContext } from '../../providers/ResultsStateProvider';

import './GroupedResultsTable.scss';
import { ConfigContext } from '../../providers/ConfigProvider';

export type GroupedResultsTableProps = ResultsTableProps & {
  groupResults: (
    results: Results | GroupedResultRows
  ) => Record<string, Results | GroupedResultRows>;
  headingFormattersForTab: (
    tabName: string
  ) => Record<string, (arg0: string) => React.JSX.Element>;
};
export const GroupedResultsTable = (props: GroupedResultsTableProps) => {
  const config = useContext(ConfigContext);
  let data: Results | GroupedResultRows = useContext(ResultsContext);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [noResults, setNoResults] = useState(false);

  const groupedData = props.groupResults(data);

  const showTab = (index: number) => {
    return (e: MouseEvent) => {
      setSelectedIndex(index);
      e.preventDefault();
    };
  };

  const labels = Object.keys(groupedData);
  // No results in the selected tab, find the first tabe that has results
  if (
    labels &&
    labels[selectedIndex] &&
    groupedData[labels[selectedIndex]].length === 0
  ) {
    labels.forEach((label, i) => {
      if (groupedData[label].length > 0) {
        setSelectedIndex(i);
      }
    });
  }

  useEffect(() => {
    setNoResults(labels.every((x) => groupedData[x].length === 0));
  }, [groupedData]);

  return (
    <>
      {noResults && (
        <p
          className="no-results"
          dangerouslySetInnerHTML={{
            __html: config.noResultsText || 'No results',
          }}
        ></p>
      )}

      {!noResults && (
        <div className="results-tabs">
          {/* <menu role="tablist">
            {labels.map((tabName, i) => {
              return (
                <li
                  role="tab"
                  aria-selected={i === selectedIndex}
                  key={`tab-button-${i}`}
                  className={
                    groupedData[tabName].length === 0 ? 'disabled' : ''
                  }
                >
                  <a href={`#tab-${i}`} onClick={showTab(i)}>
                    {tabName}
                  </a>
                </li>
              );
            })}
          </menu> */}
          {config.resultsGrouping?.showNumberTotalResults && (
            <p>
              Showing {data.length} result{data.length !== 1 && 's'}
            </p>
          )}
          {labels.map((tabName, i) => {
            return (
              <div
                className={[
                  'xwindow',
                  'xtab',
                  i === selectedIndex ? 'xtab--visible' : '',
                ].join(' ')}
                role="xtabpanel"
                id={`tab-${i}`}
                key={`tab-${i}`}
              >
                <div className="window-body">
                  {groupedData[tabName].length > 0 && (
                    <ResultsTable
                      formatters={props.formatters}
                      headingFormatters={props.headingFormattersForTab(tabName)}
                      results={groupedData[tabName]}
                      showNumberResults={
                        config.resultsGrouping?.showNumberGroupResults
                      }
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
